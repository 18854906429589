import { AchievementItem } from "@/includes/types/Achievements.types";

type AchievementItemKeys = keyof AchievementItem

export function sortAchievements(achievements: Array<AchievementItem>, criteria: AchievementItemKeys) {
  return achievements.sort().sort((a, b) => {
    if (criteria === "title") {
      return a[criteria].length - b[criteria].length
    } else {
      return a[criteria] - b[criteria]
    }
  })
}

export function makeMapAchievements(achievements: Array<AchievementItem>) {
  return achievements.reduce<Record<number, string>>((acc, currentValue) => {
    acc[currentValue.id] = currentValue.title

    return acc
  }, {})
}

export function makeListAchievements(achievements: Record<number, string>) {
  return Object.entries(achievements).reduce<Array<AchievementItem>>((acc, [ id, title ]) => {
    acc.push({
      id: +id,
      title
    })

    return acc
  }, [])
}
