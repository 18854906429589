






import HasAchievementModuleConfig from "./HasAchievementModuleConfig";
import { makeMapAchievements } from "@/includes/logic/achievements";

import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class HasAchievementModuleConfigValue extends Vue {
  @Prop() model!: HasAchievementModuleConfig

  get beautifiedAchievements() {
    const achievements = makeMapAchievements(this.$store.state.chatState.chat?.common_config?.achievements ?? {})

    const labels = this.model.value.map(id => {
      return achievements?.[id] ?? id
    })

    return labels.join(', ')
  }
}
