import { AchievementItem } from "@/includes/types/Achievements.types";

import { Component } from "vue-property-decorator";
import Vue from "vue";

@Component({})
export class AchievementsMixin extends Vue {
  achievement = { ...this.achievementModel() }

  isAddAchievementModalOpen = false

  achievementModel(): AchievementItem {
    return {
      title: '',
      id: 0,
    }
  }

  resetAchievementModel() {
    this.achievement = { ...this.achievementModel() }
  }

  get isNewAchievement() {
    return this.achievement.id === 0
  }

  addAchievement() {
    this.$store.dispatch('addAchievement', this.achievement)
        .then(this.saveConfig)
        .then(() => {
          this.isAddAchievementModalOpen = false
        })
  }

  saveConfig() {
    return this.$store.dispatch('saveChatConfigRequest', {
      config: this.$store.state.chatState.chat!.common_config
    })
  }
}
